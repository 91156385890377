/* ---Dev ---*/
export const environment = {
  production: false,
  api: {
        url: 'https://dev-api.newtribe.nl:4444',
        // url: 'http://18.216.76.76:8080',
        // url: 'https://ajax-prod-api.newtribe.nl',
        appVersion: '8',
  },
  firebase: {
    apiKey: 'AIzaSyDzGy9XnoddVbMhki6b9oYtpZXktubrpdU',
    authDomain: 'ajax-demo-d934a.firebaseapp.com',
    databaseURL: 'https://ajax-demo-d934a.firebaseio.com',
    projectId: 'ajax-demo-d934a',
    storageBucket: 'ajax-demo-d934a.appspot.com',
    messagingSenderId: '829485486228',
    appId: '1:829485486228:web:6cc3c08e3154a91ab5a481',
    measurementId: 'G-L0YLYBJZTH',

    // apiKey: 'AIzaSyD0li4Ouebwe5Eoo6X6LY-3Y5tvmluS4rM',
    // authDomain: 'ajaxprod-eac95.firebaseapp.com',
    // databaseURL: 'https://ajaxprod-eac95.firebaseio.com',
    // projectId: 'ajaxprod-eac95',
    // storageBucket: '',
    // messagingSenderId: '809951371487',
    // appId: '1:809951371487:web:c528b534d7946cf30b496a',
  },
}
